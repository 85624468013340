<template>
  <el-container>
    <el-header><HeaderBar /></el-header>
    <el-main>
      <router-view />
      <div class="login-dialog">
        <el-dialog title="" :visible.sync="loginDialog"> login </el-dialog>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import HeaderBar from "./components/HeaderBar";
import { WmpLogin } from "@/api/weixin.js";
import { is_weixin } from "@/utils/libs";
import { mapActions } from "vuex";
export default {
  name: "header-bar",
  components: {
    HeaderBar,
  },
  data() {
    return {
      loginDialog: false,
    };
  },
  methods: {
    ...mapActions("auth", ["getUserInfo"]),
    
    showQrcode() {
      //展示二维码登陆
      // this.loginDialog = true
    },
  },
  mounted() {//处理登录相关问题
    
    let token = this.$route.query["token"];
    let loginstatus = this.$route.query["loginstatus"];
    let freetrial = this.$route.query["freetrial"] == "True";
    if (freetrial) {
      this.$message("恭喜您！已领取500字体验名额");
    }
    // //如果login位真，直接调用微信登陆
    // let login = this.$route.query["login"] == "true";
    // if (login) {
    //   WmpLogin(encodeURI(window.location.href.split("?")[0]));
    // }
    //获取用户信息
    this.getUserInfo(token)
      .then(({ code, data }) => {
        //登陆成功回到首页
        let path = this.$route["path"];
        if (this.$route.query["token"]) {
          this.$router.push({ path });
        }
      })
      .catch(() => {});
  },
  created() {
    // 禁用缩放
    function addMeta() {
      let meta = document.createElement("meta");
      meta.name = "viewport";
      meta.content =
        "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no";
      document.getElementsByTagName("head")[0].appendChild(meta);
    }
    setTimeout(addMeta, 3000);

    // 禁用双指放大
    document.documentElement.addEventListener(
      "touchstart",
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      {
        passive: false,
      }
    );

    // 禁用双击放大
    var lastTouchEnd = 0;
    document.documentElement.addEventListener(
      "touchend",
      function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      {
        passive: false,
      }
    );
  },
};
</script>

<style lang="stylus">
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.el-header {
  background-color: #B3C0D1;
  color: #333;
  text-align: left;
  line-height: 60px;
  background-image: linear-gradient(120deg, #3565ff, #002ec8 98%);
  color: #fff;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  display: block;
  box-sizing: border-box;
  background-color: #E9EEF3;
  color: #333;
  display: block;
  position: absolute;
  text-align: left;
  top: 60px;
  bottom: 0px;
  width: 100%;
  overflow-y: scroll;
  background-image: url('https://cdn.17keep.cn/22220c159fxw17h6o40145p24a812lrzt181625bdq21yeumn13iskvg3107');
  background-size: auto 150%;
  background-repeat: no-repeat;
  background-position: center center;
  // background-size: cover;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside, .el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.el-main {
  padding: 5px !important;
  padding-top: 20px !important;
}
</style>
