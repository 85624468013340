<template>
  <div class="header-wrapper">
    <div class="head-logo-item">
      <img class="logo" src="@/assets/imgs/logo.png" />
    </div>
    <div @click="drawer = true" class="more-btn">
      <i class="el-icon-more"></i>
    </div>
    <el-drawer
      title="我是标题"
      :visible.sync="drawer"
      :with-header="false"
      size="50%"
      direction="rtl"
    >
      <el-menu class="el-menu-vertical-demo" router>
        <el-menu-item
          :index="item['path']"
          v-for="(item, k) in navRouter"
          :key="k"
          @click="closeDrawer"
        >
          <i :class="item['meta']['icon']"></i>
          <span slot="title">{{ item["meta"]["title"] }}</span>
        </el-menu-item>
        <el-menu-item @click="logOut">
          <i class="el-icon-switch-button"></i>
          <span slot="title">重新登陆</span>
        </el-menu-item>
      </el-menu>
    </el-drawer>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      drawer: false,
      navRouter: [],
    };
  },
  name: "header-bar",
  methods: {
    ...mapActions("auth", ["logOut"]),
    closeDrawer(){
      setTimeout(() => {
        this.drawer = false
      }, 500);
      
    }
  },
  mounted() {
    let navRouter = this.$router.options["routes"].filter((v, i) => {
      return v.name == "mobile";
    });
    this.navRouter = navRouter[0]["children"].filter(
      (v, i) => v["meta"]["show4nav"]
    );
  },
};
</script>

<style lang="stylus" scoped>
.header-wrapper {
  display: block;
  position: relative;
  box-sizing: border-box;
  height: 100%;

  // background-color: #ccc
  .head-logo-item {
    display: inline-block;
    width: 245px;
    height: 100%;
    position: relative;

    .logo {
      width: 100%;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }
  }

  .more-btn {
    display: inline-block;
    width: 36px;
    height: 50px;
    color: #fff;
    font-size: 36px;
    line-height: 50px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
</style>